import {Endpoint} from "../endpoint";
import {GetAccountStatusSuccessfulResponse} from "../../responses/deposits/GetAccountStatusSuccessfulResponse";
export class GetAccountStatusEndpoint extends Endpoint {
    static url() {
        return 'downstream/ramps-manager/fiat/accounts?country=AR&currency=ARS';
    }

    ownResponses() {
        return [GetAccountStatusSuccessfulResponse];
    }

    method() {
        return 'GET';
    }

    needsAuthorization() {
        return true;
    }
} 
