import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useState as hookstateUseState } from "@hookstate/core";
import { app } from "app/app";
import { Currency } from "buenLib/domain/Currency";

export const AppContext = React.createContext({});


export const AppContextConsumer = AppContext.Consumer;

export const AppContextProvider = ({ children }) => {
    const [loadingMarketTickers, setLoadingMarketTickers] = useState(true);
    const [currencies, setCurrencies] = useState([]);
    const [markets, setMarkets] = useState([]);
    const marketTickers = hookstateUseState(null);
    const [taxes, setTaxes] = useState([]);
    const [visible, setVisible] = useState(true);
    const [accountStatus, setAccountStatus] = useState(null);
    const [loadingAccountStatus, setLoadingAccountStatus] = useState(true);

    const marketIdentifiers = useMemo(() => {
        return markets
            .filter((market) => {
                return (
                    Currency.webappEnabledCurrencies().indexOf(
                        market.bidCurrency()
                    ) > -1
                );
            })
            .map((market) => market.identifier());
    }, [markets]);

    const currencyCodes = useMemo(
        () => currencies.map((currency) => currency.lowercaseCode()),
        [currencies]
    );

    const currencyMap = useMemo(
        () =>
            currencies.reduce((acc, currency) => {
                acc.set(currency.lowercaseCode(), currency);
                return acc;
            }, new Map()),
        [currencies]
    );

    const fecthTaxes = async () => {
        const taxes = await app.fetchTaxes();
        setTaxes(taxes._jsonResponse.object);
    };

    const fetchAccountStatus = async () => {
        try {
            const response = await app.getAccountStatus();
            setAccountStatus(response._jsonResponse);
        } catch (error) {
            setAccountStatus(null);
        } finally {
            setLoadingAccountStatus(false);
        }
    };

    useEffect(() => {
        const fetchCurrencies = async () => {
            setCurrencies(await app.fetchCurrencies());
        };
        fetchCurrencies();
    }, []);

    useEffect(() => {
        const fetchMarkets = async () => {
            setLoadingMarketTickers(true);
            setMarkets(await app.fetchMarkets());
        };
        if (currencies.length) fetchMarkets();
    }, [currencies]);

    useEffect(() => {
        if (marketIdentifiers.length) {
            const marketValues = app.getMarketTickersFromLocalStorage();
            marketTickers.set(
                app.buildMarketTickers(marketIdentifiers, marketValues)
            );
            setLoadingMarketTickers(false);
        }
    }, [marketIdentifiers]);

    useEffect(() => {
        const stored = localStorage.getItem("visibleBalance") ?? true;
        setVisible(stored);
    }, []);

    useEffect(() => {
        fetchAccountStatus();
    }, []);

    const getCurrencyInstance = useCallback(
        (currencyCode) => currencyMap.get(currencyCode),
        [currencyMap]
    );

    const saveVisibility = async (payload) => {
        setVisible(payload);

        if (payload === null) {
            localStorage.deleteItem("visibleBalance");
        } else {
            localStorage.setItem("visibleBalance", payload);
        }
    };

    return (
        <AppContext.Provider
            value={{
                currencies,
                currencyCodes,
                getCurrencyInstance,
                markets,
                marketIdentifiers,
                marketTickers,
                loadingMarketTickers,
                taxes,
                visible,
                fecthTaxes,
                saveVisibility,
                accountStatus,
                loadingAccountStatus,
                fetchAccountStatus,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
