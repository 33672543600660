import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import {Currency} from "buenLib/domain/Currency";
import {ShowChildrenWhenTrue} from "buenLib/components/ShowChildrenWhenTrue";
import {CopyToClipboardElement} from "components/dashboard/common/CopyToClipboardElement";
import {app} from "app/app";
/* import 'assets/sass/dashboard/deposits/Deposits.scss'; */

/* import { featureFlags } from 'buenLib/communication/src'; */

import Group from 'components/dashboard/common/Group';
import GlassBox from 'components/dashboard/common/GlassBox';
import { images } from "assets/img/common/common";
import StoresBadges from "components/common/StoreBadges";
import { AppContext } from "app/state/AppContext";
import Spinner from "components/dashboard/common/Spinner";
export class Deposits extends Component {
    static contextType = AppContext;

    componentDidMount() {
        const { fetchAccountStatus } = this.context;
        fetchAccountStatus();
    }

    constructor(props) {
        super(props);

        this.state = {
            transferringFromOwnAccountInARS: false,
        };
        this.renderCVUNotCurrentlyAvailableMessage = this.renderCVUNotCurrentlyAvailableMessage.bind(this);
    }

    renderContent() {
        if (this.props.currency.lowercaseCode() !== Currency.ARS) {
            return this.renderAccountInformationToMakeDeposit();
        }

        const { accountStatus , loadingAccountStatus} = this.context;

        if (loadingAccountStatus) {
            return <Spinner />;
        }


        if (accountStatus && accountStatus.length > 0) {
            return this.renderNewAccountInterface(accountStatus);
        }

        if (!accountStatus) {
            return this.renderAccountInformationToMakeDeposit();
        }

        return this.renderQRForAppAccount();
    }

    renderQRForAppAccount() {
        return (
            <GlassBox margin="20px 0">
                <Group gap="12" direction="column" align="center">
                    <h3>Descargá la app para crear tu CVU</h3>
                    <div>
                        <img
                            src={images.newQRStore}
                            alt="QR para descargar app"
                        />
                    </div>
                    <p>Escaneá el QR con la cámara de tu celular y descargá Buenbit</p>
                    <StoresBadges />
                </Group>
            </GlassBox>
        );
    }

    renderNewAccountInterface(accounts) {
        const userDepositData = accounts[0];
        return (
            <>
                <p>Para ingresar dinero a tu cuenta debes realizar una transferencia al siguiente CVU:</p>

                <ShowChildrenWhenTrue condition={this.canDeposit()}>
                    <GlassBox
                        margin="20px 0"
                    >
                        <Group gap="12">
                            <h4>Titular</h4>
                            <p>{userDepositData.owner_name} (CUIT/CUIL: {userDepositData.owner_tax_id})</p>
                        </Group>
                        <hr />
                        <Group gap="12">
                            <h4>CVU</h4>
                            <Group
                                justify="center"
                                gap="15"
                            >
                                <p>{userDepositData.uniform_key}</p>
                                <CopyToClipboardElement
                                    valueToCopy={userDepositData.uniform_key}
                                    mouseOverTooltipMessage="Clickeá para copiar el CVU"
                                    copiedTooltipMessage="¡CVU copiado al portapapeles!"
                                />
                            </Group>
                        </Group>
                        <hr />
                        <Group gap="12">
                            <h4>Alias</h4>
                            <Group
                                justify="center"
                                gap="15"
                            >
                                <p>{userDepositData.alias}</p>
                                <CopyToClipboardElement
                                    valueToCopy={userDepositData.alias}
                                mouseOverTooltipMessage="Clickeá para copiar el alias"
                                    copiedTooltipMessage="¡Alias copiado al portapapeles!"
                                />
                            </Group>
                        </Group>
                        <hr />
                        <Group gap="12">
                            <h4>Banco</h4>
                            <p>{userDepositData.bank_name}</p>
                        </Group>
                    </GlassBox>
                    <p className="disclaimer">* Las acreditaciones en nuestro sistema son automáticas, pero pueden demorar hasta 2 horas dependiendo de la velocidad de procesamiento de cada banco.</p>
                </ShowChildrenWhenTrue>
            </>
        );
    }

    render() {
        return (
            <div>
                {this.renderContent()}
            </div>
        );
    }

    getCVU() {
        return this.props.cvu;
    }

    canDeposit() {
        return app.currentUser().canWithdrawFiat() && app.currentUser().isSafe();
    }

    renderCBUArsInformation() {
        return (
            <ShowChildrenWhenTrue
                condition={app.currentUser().canWithdrawFiat()}
                elementsToShowWhenFalse={this.renderUserNotSafeMessage()}
            >
                <Group
                    justify="center"
                    gap="15"
                >
                    <p>3220001805006401160034</p>
                    <CopyToClipboardElement
                        valueToCopy="3220001805006401160034"
                        mouseOverTooltipMessage="Clickeá para copiar el CBU"
                        copiedTooltipMessage="¡CBU copiado al portapapeles!"
                    />
                </Group>
            </ShowChildrenWhenTrue>
        )
    }

    renderCVUArsInformation() {
        return (
            <ShowChildrenWhenTrue
                condition={app.currentUser().canWithdrawFiat()}
                elementsToShowWhenFalse={this.renderUserNotSafeMessage()}
            >
                <Group
                    justify="center"
                    gap="15"
                >
                    <p>0000053600000000713320</p>
                    <CopyToClipboardElement
                        valueToCopy="0000053600000000713320"
                        mouseOverTooltipMessage="Clickeá para copiar el CVU"
                        copiedTooltipMessage="CVU copiado al portapapeles!"
                    />
                </Group>
            </ShowChildrenWhenTrue>
        )
    }

    renderCBUSecondChoiceArsInformation() {
        if (app.currentUser().canWithdrawFiat()) {
            return (
                <Group
                    justify="center"
                    gap="15"
                >
                    <p>3220001805006401160065</p>
                    <CopyToClipboardElement
                        valueToCopy="3220001805006401160065"
                        mouseOverTooltipMessage="Clickeá para copiar el CBU"
                        copiedTooltipMessage="¡CBU copiado al portapapeles!"
                    />
                </Group>
            )
        } else {
            return this.renderUserNotSafeMessage()
        }
    }

    renderArsAccountInformation() {
        return (
            <ShowChildrenWhenTrue
                condition={app.currentUser().canWithdrawFiat()}
                elementsToShowWhenFalse={this.renderUserNotSafeMessage()}
            >
                <p>Cuenta Corriente 1-640116/3</p>
            </ShowChildrenWhenTrue>
        )
    }

    renderUsdAccountInformation() {
        return (
            <ShowChildrenWhenTrue
                condition={app.currentUser().canWithdrawFiat()}
                elementsToShowWhenFalse={this.renderUserNotSafeMessage()}
            >
                <p>Cuenta Corriente 1-640116/2</p>
            </ShowChildrenWhenTrue>
        )
    }

    renderAliasArsInformation() {
        return (
            <ShowChildrenWhenTrue
                condition={app.currentUser().canWithdrawFiat()}
                elementsToShowWhenFalse={this.renderUserNotSafeMessage()}
            >
                <Group
                    justify="center"
                    gap="15"
                >
                    <p>BUENBIT.ARS</p>
                    <CopyToClipboardElement
                        valueToCopy="BUENBIT.ARS"
                        mouseOverTooltipMessage="Clickeá para copiar el alias"
                        copiedTooltipMessage="¡Alias copiado al portapapeles!"
                    />
                </Group>
            </ShowChildrenWhenTrue>
        )
    }

    renderCVUAliasArsInformation() {
        return (
            <ShowChildrenWhenTrue
                condition={app.currentUser().canWithdrawFiat()}
                elementsToShowWhenFalse={this.renderUserNotSafeMessage()}
            >
                <Group
                    justify="center"
                    gap="15"
                >
                    <p>ARS.BUENBIT</p>
                    <CopyToClipboardElement
                        valueToCopy="ARS.BUENBIT"
                        mouseOverTooltipMessage="Clickeá para copiar el alias"
                        copiedTooltipMessage="¡Alias copiado al portapapeles!"
                    />
                </Group>
            </ShowChildrenWhenTrue>
        )
    }

    renderUserNotSafeMessage() {
        const verificationStateCode = app.currentUser().verificationState().code();
        const defaultMessage = "No podemos acreditar depósitos a tu cuenta hasta finalizar la verificación de la misma.";
        let errorMessage = this._getOrDefaultErrorMessage(verificationStateCode, defaultMessage);
        return <p className="error">{errorMessage}</p>;
    }

    _getOrDefaultErrorMessage(errorCode, defaultMessage) {
        return app.errorMessageMapper()[errorCode] ? app.errorMessageMapper()[errorCode] : defaultMessage;
    }

    renderCBUUsdInformation() {
        return (
            <ShowChildrenWhenTrue
                condition={app.currentUser().canWithdrawFiat()}
                elementsToShowWhenFalse={this.renderUserNotSafeMessage()}
            >
                <Group
                    justify="center"
                    gap="15"
                >
                    <p>3220001812006401160021</p>
                    <CopyToClipboardElement
                        valueToCopy="3220001812006401160021"
                        mouseOverTooltipMessage="Clickeá para copiar el CBU"
                        copiedTooltipMessage="¡CBU copiado al portapapeles!"
                    />
                </Group>
            </ShowChildrenWhenTrue>
        )
    }

    renderAliasUsdInformation() {
        return (
            <ShowChildrenWhenTrue
                condition={app.currentUser().canWithdrawFiat()}
                elementsToShowWhenFalse={this.renderUserNotSafeMessage()}
            >
                <Group
                    justify="center"
                    gap="15"
                >
                    <p>BUENBIT.USD</p>
                    <CopyToClipboardElement
                        valueToCopy="BUENBIT.USD"
                        mouseOverTooltipMessage="Clickeá para copiar el alias"
                        copiedTooltipMessage="¡Alias copiado al portapapeles!"
                    />
                </Group>
            </ShowChildrenWhenTrue>
        )
    }

    renderCoinagAliasUsdInformation() {
        return (
            <ShowChildrenWhenTrue
                condition={app.currentUser().canWithdrawFiat()}
                elementsToShowWhenFalse={this.renderUserNotSafeMessage()}
            >
                <Group
                    justify="center"
                    gap="15"
                >
                    <p>BUENBIT.DOLAR</p>
                    <CopyToClipboardElement
                        valueToCopy="BUENBIT.DOLAR"
                        mouseOverTooltipMessage="Clickeá para copiar el alias"
                        copiedTooltipMessage="¡Alias copiado al portapapeles!"
                    />
                </Group>
            </ShowChildrenWhenTrue>
        )
    }

    renderCoinagCBUUsdInformation() {
        return (
            <ShowChildrenWhenTrue
                condition={app.currentUser().canWithdrawFiat()}
                elementsToShowWhenFalse={this.renderUserNotSafeMessage()}
            >
                <Group
                    justify="center"
                    gap="15"
                >
                    <p>4310009942700000033119</p>
                    <CopyToClipboardElement
                        valueToCopy="4310009942700000033119"
                        mouseOverTooltipMessage="Clickeá para copiar el CBU"
                        copiedTooltipMessage="¡CBU copiado al portapapeles!"
                    />
                </Group>
            </ShowChildrenWhenTrue>
        )
    }

    renderAccountInformationToMakeDeposit() {
        if (this.props.currency.lowercaseCode() === Currency.ARS) {
            return this.renderARSInformationForTransferringFromOwnAccount();
        } else {
            return this.renderUSDInformationForTransferringFromOwnAccount();
        }
    }

    renderCVUNotCurrentlyAvailableMessage() {
        return (
            <div className="text-center">
                Todavía estamos generando tu CVU. Pronto vas a poder transferir desde cualquier cuenta a tu CVU
                para acreditar dinero en {app.productName()}. Por favor reintentá más tarde.
                <br />
                <br />
                {this.state.transferringFromOwnAccountInARS ? this.renderARSInformationForTransferringFromOwnAccount() :
                    <button className="link-btn" onClick={() => {
                        this.setState({ transferringFromOwnAccountInARS: true })
                    }}>
                        Transfiero desde cuenta propia
                    </button>
                }
            </div>
        );
    }

    renderCVU() {
        return (
            <div className="text-center">
                <p>
                    Para ingresar dinero en tu cuenta debes realizar una transferencia a la siguiente cuenta:
                </p>
                <p>Copiá tu CVU</p>
                <span className="wallet-deposit-address">{this.getCVU()}</span>
                <CopyToClipboardElement
                    valueToCopy={this.getCVU()}
                    mouseOverTooltipMessage="Clickeá para copiar tu CVU"
                    copiedTooltipMessage="¡CVU copiada al portapapeles!"
                />
            </div>
        )
    }

    renderUSDInformationForTransferringFromOwnAccount() {
        let userNotSafe = <div className="deposits-card">
            {this.renderUserNotSafeMessage()}
        </div>
        return (
            <>
                <p>Para ingresar dinero a tu cuenta debes realizar una transferencia al siguiente CBU desde una cuenta asociada a <Link to={app.routes().dashboard.user.profile}>tu CUIT</Link>:</p>

                <ShowChildrenWhenTrue condition={this.canDeposit()} elementsToShowWhenFalse={userNotSafe}>
                    <GlassBox
                        margin="20px 0"
                    >
                        <Group gap="12">
                            <h4>Razón social</h4>
                            <p>BTC Trade SRL (CUIT: 30715669478)</p>
                        </Group>
                        <hr />
                        <Group gap="12">
                            <h4>CBU</h4>
                            {this.renderCBUUsdInformation()}
                        </Group>
                        <hr />
                        <Group gap="12">
                            <h4>Alias</h4>
                            {this.renderAliasUsdInformation()}
                        </Group>
                        <hr />
                        <Group gap="12">
                            <h4>Banco</h4>
                            <p>Banco Industrial S.A. Sucursal 1</p>
                        </Group>
                        <hr />
                        <Group gap="12">
                            <h4>Cuenta</h4>
                            {this.renderUsdAccountInformation()}
                        </Group>
                    </GlassBox>
                    <p className="disclaimer">* Solo podemos acreditar transferencias desde una cuenta a tu nombre.</p>
                    <p className="disclaimer">* Las acreditaciones en nuestro sistema son automáticas, pero pueden demorar hasta 2 horas dependiendo de la velocidad de procesamiento de cada banco.</p>
                </ShowChildrenWhenTrue>
            </>
        );
    }

    renderARSInformationForTransferringFromOwnAccount() {
        let userNotSafe = <div className="deposits-card">
            {this.renderUserNotSafeMessage()}
        </div>
        return (
            <>
                <p> Para ingresar dinero a tu cuenta debes realizar una transferencia al siguiente CBU desde una cuenta asociada a <Link to={app.routes().dashboard.user.profile}>tu CUIT/CUIL</Link>:</p>

                <ShowChildrenWhenTrue condition={this.canDeposit()} elementsToShowWhenFalse={userNotSafe}>
                    <GlassBox
                        margin="20px 0"
                    >
                        <Group gap="12">
                            <h4>Razón social</h4>
                            <p>BTC Trade SRL (CUIT: 30715669478)</p>
                        </Group>
                        <hr />
                        <Group gap="12">
                            <h4>CBU</h4>
                            {this.renderCBUArsInformation()}
                        </Group>
                        <hr />
                        <Group gap="12">
                            <h4>Alias</h4>
                            {this.renderAliasArsInformation()}
                        </Group>
                        <hr />
                        <Group gap="12">
                            <h4>Banco</h4>
                            <p>Banco Industrial S.A. Sucursal 1</p>
                        </Group>
                        <hr />
                        <Group gap="12">
                            <h4>Cuenta</h4>
                            {this.renderArsAccountInformation()}
                        </Group>
                    </GlassBox>
                    <p className="disclaimer">* Solo podemos acreditar transferencias desde una cuenta a tu nombre.</p>
                    <p className="disclaimer">* Las acreditaciones en nuestro sistema son automáticas, pero pueden demorar hasta 2 horas dependiendo de la velocidad de procesamiento de cada banco.</p>
                </ShowChildrenWhenTrue>
            </>
        );
    }
}
