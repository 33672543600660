import React, { Component } from "react";
import { Deposits } from "components/dashboard/deposit-and-withdraw/deposits/fiat/Deposits";
import { Withdraws } from "components/dashboard/deposit-and-withdraw/withdraws/fiat/Withdraws";
import { WithdrawOperationForm } from "models/forms/WithdrawOperationForm";
import { NewBankAccount } from "models/forms/NewBankAccount";
import { OperationErrorAlert } from "components/dashboard/common/OperationErrorAlert";
import { AccountAddedAnnouncement } from "buenLib/gtmNotifications/announcements/withdraws/AccountAddedAnnouncement";
import { WithdrawExecutedAnnouncement } from "buenLib/gtmNotifications/announcements/withdraws/WithdrawExecutedAnnouncement";
import { ValidationErrorAnnouncement } from "buenLib/gtmNotifications/announcements/general/ValidationErrorAnnouncement";
import { AddAccountSecondStepStartedAnnouncement } from "buenLib/gtmNotifications/announcements/trackEvents/AddAccountSecondStepStartedAnnouncement";
import { BankAccountBook } from "models/BankAccountBook";
import { app } from "app/app";
import "assets/sass/dashboard/deposits/OperationsPanel.scss";
import { Modal } from "components/common/Modal";
import { ConfirmationTokenRequiredResponse } from "buenLib/communication/src/responses/generalResponses/ConfirmationTokenRequiredResponse";
import { ModalWithReadCheck } from "components/common/ModalWithReadCheck";
import { Decimal } from "decimal.js";
import { AppContext } from "app/state/AppContext";
import { Currency } from "buenLib/domain/Currency";

import CardContent from "components/common/CardContent";
import Spinner from "components/dashboard/common/Spinner";

export class FiatDepositsAndWithdrawsController extends Component {
    static DEPOSIT_OPERATION_NAME = "deposit";
    static WITHDRAW_OPERATION_NAME = "withdraw";
    static contextType = AppContext;

    constructor(props) {
        super(props);

        let query = Object.fromEntries(
            this.props.location.search
                .replace(/^\?/, "")
                .split("&")
                .map((keyval) =>
                    keyval
                        .split("=")
                        .map((component) => decodeURIComponent(component))
                )
        );

        this.state = {
            loadingWithdrawsConfiguration: true,
            loadingAccountBalance: true,
            loadingBankAccounts: true,
            //loadingDepositLimitsStatus: true,
            loadingCVU: true,
            operationInProgress: false,
            errorResponse: undefined,
            activeTab: this.props.operationType,
            data: {
                balance: {},
                bankAccountBook: {},
                cvu: "",
                //depositLimitsStatus: undefined,
            },
            errors: {
                cvu: undefined,
            },
            newWithdrawForm: new WithdrawOperationForm({
                currency: props.currency,
            }),
            taxes: {
                rateTaxes: props.taxes
                    .reduce((acc, current) => acc + current.value, 0),
            },
            newBankAccount: new NewBankAccount({
                currency: props.currency,
                initialConfirmationToken: query.token,
                initialUniformKeyOrAlias: query.uniform_key_or_alias,
            }),
            confirmationTokenSent: null,
        };

        this.handleGetBalanceResponse =
            this.handleGetBalanceResponse.bind(this);
        //this.handleGetDepositLimitsStatusResponse = this.handleGetDepositLimitsStatusResponse.bind(this);
        this.getCVU = this.getCVU.bind(this);
        this.handleGetOrCreateCVUResponse =
            this.handleGetOrCreateCVUResponse.bind(this);
        this.announceWithdrawExecutedSuccessful =
            this.announceWithdrawExecutedSuccessful.bind(this);
        this.announceValidationError = this.announceValidationError.bind(this);
        this.announceAddAccountSecondStepStarted =
            this.announceAddAccountSecondStepStarted.bind(this);
        this.handleGetChangedBalanceResponseAndAnnounceExecutedWithdrawSuccessful =
            this.handleGetChangedBalanceResponseAndAnnounceExecutedWithdrawSuccessful.bind(
                this
            );
        this.handleGetBankAccountBookResponse =
            this.handleGetBankAccountBookResponse.bind(this);
        this.handleWithdrawAmountChange =
            this.handleWithdrawAmountChange.bind(this);
        this.handleWithdrawAccountChange =
            this.handleWithdrawAccountChange.bind(this);
        this.handleDeleteBankAccount = this.handleDeleteBankAccount.bind(this);
        this.confirmWithdraw = this.confirmWithdraw.bind(this);
        this.handleBankAccountIdentifierChange =
            this.handleBankAccountIdentifierChange.bind(this);
        this.handleConfirmationTokenChange =
            this.handleConfirmationTokenChange.bind(this);
        this.handleGetWithdrawsConfigurationResponse =
            this.handleGetWithdrawsConfigurationResponse.bind(this);
        this.checkAccountIdentifier = this.checkAccountIdentifier.bind(this);
        this.createBankAccount = this.createBankAccount.bind(this);
        this.cleanWithdrawForm = this.cleanWithdrawForm.bind(this);
        this.resetToOperationType = this.resetToOperationType.bind(this);
    }

    componentDidMount() {
        this.getAccountBalance((accountBalanceResponse) => {
            this.handleGetBalanceResponse(accountBalanceResponse);
            this.getWithdrawsConfiguration((withdrawConfigResponse) => {
                this.handleGetWithdrawsConfigurationResponse(
                    withdrawConfigResponse
                );
                /* this.getDepositLimitsStatus(
                    this.handleGetDepositLimitsStatusResponse
                ); */
                this.defineInitialTab();
                // TODO-CVU: change all text appearences of CBU to CBU/CVU
                // if (app.currentUser().canWithdrawFiat()) {
                // this.getCVU();
                // }
                this.getBankAccounts();
                // if we received a uniform key or alias from querystring, skip to confirm step
                if (!!this.state.newBankAccount.uniformKeyOrAlias()) {
                    this.checkAccountIdentifier();
                }
            });
        });
    }

    cleanWithdrawForm() {
        let newWithdrawForm = this.state.newWithdrawForm;
        newWithdrawForm.updateAccount(undefined);
        newWithdrawForm.updateAmount("");
        newWithdrawForm.resetFieldErrors();
        this.setState({ newWithdrawForm: newWithdrawForm });
    }

    updateData(dataKey, dataValue, callback) {
        let data = this.state.data;
        let newWithdrawForm = this.state.newWithdrawForm;
        data[dataKey] = dataValue;
        newWithdrawForm.updateExtraData(data);

        this.setState(
            { data: data, newWithdrawForm: newWithdrawForm },
            callback
        );
    }

    handleGetBalanceResponse(response) {
        let data = this.state.data;
        data.balance = response.accountBalance();
        this.setState({ data: data, loadingAccountBalance: false });
    }

    handleGetWithdrawsConfigurationResponse(response) {
        let query = Object.fromEntries(
            this.props.location.search
                .replace(/^\?/, "")
                .split("&")
                .map((keyval) =>
                    keyval
                        .split("=")
                        .map((component) => decodeURIComponent(component))
                )
        );
        let data = response.content();

        let form = new WithdrawOperationForm({
            currency: this.props.currency,
            balance: new Decimal(
                this.state.data.balance[this.props.currency.lowercaseCode()]
            ),
            initialAmount: query.amount ? new Decimal(query.amount) : undefined,
            minimumAmount: data.minimum_amount
                ? new Decimal(data.minimum_amount)
                : undefined,
            maximumAmount: data.maximum_amount
                ? new Decimal(data.maximum_amount)
                : undefined,
            fee: data.fee ? new Decimal(data.fee) : undefined,
        });
        this.setState({
            loadingWithdrawsConfiguration: false,
            newWithdrawForm: form,
        });
    }

    /* handleGetDepositLimitsStatusResponse(response) {
        const depositLimitsStatus = response.depositLimitsStatus();
        this.updateData("depositLimitsStatus", depositLimitsStatus, () => {
            this.setState({ loadingDepositLimitsStatus: false });
        });
    } */

    getCVU() {
        this.setState({ loadingCVU: true });
        app.apiClient().getOrCreateCVU(this.handleGetOrCreateCVUResponse);
    }

    handleGetOrCreateCVUResponse(response) {
        let data = this.state.data;
        let errors = this.state.errors;

        if (response.hasError()) {
            errors.cvu = response.errors();
        } else {
            data.cvu = response.cvu();
        }

        this.setState({ data: data, errors: errors, loadingCVU: false });
    }

    announceWithdrawExecutedSuccessful(newWithdrawForm) {
        app.announcer().announce(
            WithdrawExecutedAnnouncement.newWith(
                app.currentUser(),
                newWithdrawForm.amount(),
                newWithdrawForm.currency()
            )
        );
    }

    announceValidationError(action, error) {
        const user = app.currentUser();
        app.announcer().announce(
            new ValidationErrorAnnouncement(user, "retiros", action, error)
        );
    }

    announceAddAccountSecondStepStarted() {
        const user = app.currentUser();
        app.announcer().announce(
            new AddAccountSecondStepStartedAnnouncement(user)
        );
    }

    handleGetChangedBalanceResponseAndAnnounceExecutedWithdrawSuccessful(
        response,
        newWithdrawForm
    ) {
        this.handleGetBalanceResponse(response);
        this.announceWithdrawExecutedSuccessful(newWithdrawForm);
    }

    handleGetBankAccountBookResponse(response) {
        const bankAccountBookData = response.bankAccountBookAsJSON();
        let bankAccountBook = new BankAccountBook();
        bankAccountBook.addAccounts(bankAccountBookData);
        this.updateData("bankAccountBook", bankAccountBook, () => {
            this.setState({ loadingBankAccounts: false });
        });
    }

    getAccountBalance(responseHandler) {
        app.apiClient().getAccountBalance(responseHandler);
    }

    /* getDepositLimitsStatus(responseHandler) {
        this.setState({ loadingDepositLimitsStatus: true });
        app.apiClient().getDepositLimitsStatus(responseHandler);
    } */

    getBankAccounts() {
        this.setState({ loadingBankAccounts: true });
        app.apiClient().getBankAccountsBook(
            this.handleGetBankAccountBookResponse
        );
    }

    getWithdrawsConfiguration(onResponse) {
        this.setState({ loadingWithdrawsConfiguration: true }, () => {
            app.apiClient().getWithdrawsConfiguration(
                this.props.currency.lowercaseCode(),
                onResponse
            );
        });
    }

    defineInitialTab() {
        if (
            this.props.location.state &&
            this.props.location.state.operationFinished
        ) {
            this.resetToOperationType(
                FiatDepositsAndWithdrawsController.WITHDRAW_OPERATION_NAME
            );
        }
    }

    getCurrency() {
        let { getCurrencyInstance } = this.context;
        return getCurrencyInstance(this.props.currency);
    }

    handleDeleteBankAccount() {
        this.getBankAccounts();
    }

    handleWithdrawAmountChange(event) {
        let formattedValue = event.target.value
            .replace(/\./g, "")
            .replace(/,/g, ".");
        if (!formattedValue || isNaN(formattedValue)) {
            formattedValue = undefined;
        }

        const fieldName = event.target.name;
        let newWithdrawForm = this.state.newWithdrawForm;

        let newTaxes = 0;
        if (this.state.taxes.rateTaxes > 0) {
            newTaxes += (this.state.taxes.rateTaxes / 100) * formattedValue;
        }

        newWithdrawForm.update("taxes", newTaxes);
        newWithdrawForm.update(fieldName, formattedValue);
        this.setState({ newWithdrawForm: newWithdrawForm });
    }

    handleWithdrawAccountChange(account) {
        let newWithdrawForm = this.state.newWithdrawForm;
        if (newWithdrawForm.account() === account) {
            newWithdrawForm.updateAccount();
        } else {
            newWithdrawForm.updateAccount(account);
        }
        this.setState({ newWithdrawForm: newWithdrawForm });
    }

    confirmWithdraw(callback) {
        let newWithdrawForm = this.state.newWithdrawForm;
        if (newWithdrawForm.hasErrors()) return;

        this.setState({ operationInProgress: true, errorResponse: undefined });
        newWithdrawForm.save((response) => {
            if (response.hasError()) {
                this.setState({ errorResponse: response });
            } else {
                newWithdrawForm.addWithdrawID(response.content().tid);
                this.setState({ newWithdrawForm: newWithdrawForm });
                this.getAccountBalance((response) =>
                    this.handleGetChangedBalanceResponseAndAnnounceExecutedWithdrawSuccessful(
                        response,
                        newWithdrawForm
                    )
                );
                callback();
            }
            this.setState({ operationInProgress: false });
        });
    }

    handleBankAccountIdentifierChange(event) {
        const value = event.target.value;
        const fieldName = event.target.name;

        let newBankAccount = this.state.newBankAccount;
        newBankAccount.update(fieldName, value);
        this.setState({ newBankAccount: newBankAccount });
    }

    handleConfirmationTokenChange(name, value) {
        let newBankAccount = this.state.newBankAccount;
        newBankAccount.update(name, value);
        this.setState({ newBankAccount });
    }

    checkAccountIdentifier(callback, exceptionCallback) {
        let newBankAccount = this.state.newBankAccount;

        if (newBankAccount.hasErrors()) {
            this.setState({ newBankAccount: newBankAccount });
            this.announceValidationError(
                "Agregar cuenta",
                "Debes ingrear un cbu o alias"
            );
            return;
        }

        this.setState({ operationInProgress: true, errorResponse: undefined });
        newBankAccount.checkIdentifier((response) => {
            if (response.hasError()) {
                newBankAccount.setFieldErrors({
                    [NewBankAccount.UNIFORM_KEY_OR_ALIAS_FIELD_NAME]:
                        response.errorMessages(),
                });
                this.setState({
                    operationInProgress: false,
                    newBankAccount: newBankAccount,
                });
            } else {
                let bankAccounts = response.bankAccountsData();
                newBankAccount.updateBankAccount(bankAccounts[0]);
                if (bankAccounts.length > 1) {
                    newBankAccount.markMultipleAccountsToConfirm();
                }
                this.setState(
                    {
                        operationInProgress: false,
                        newBankAccount: newBankAccount,
                    },
                    callback
                );
            }
        }, exceptionCallback);
    }

    createBankAccount(callback, errorCallback) {
        let newBankAccount = this.state.newBankAccount;
        if (newBankAccount.hasErrors()) return;

        this.setState({ operationInProgress: true, errorResponse: undefined });
        newBankAccount.save((response) => {
            this.setState({ operationInProgress: false });

            if (response.hasError()) {
                if (response instanceof ConfirmationTokenRequiredResponse) {
                    this.setState({
                        confirmationTokenSent: response,
                    });
                } else {
                    this.setState({ errorResponse: response });
                    errorCallback();
                }
            } else {
                callback();
                app.announcer().announce(
                    new AccountAddedAnnouncement(
                        app.currentUser(),
                        newBankAccount.bankAccount()
                    )
                );
                this.getBankAccounts();
                this.state.newBankAccount.resetFieldValues();
                this.setState({ newBankAccount: this.state.newBankAccount });
                this.resetToOperationType(this.props.operationType);
            }
        }, errorCallback);
    }

    isADepositOperation() {
        return (
            this.props.operationType === this.constructor.DEPOSIT_OPERATION_NAME
        );
    }

    resetToOperationType(operationType) {
        const { currencyCodes } = this.context;
        window.history.pushState(
            {},
            null,
            app.currencyDependentRoutes(currencyCodes).dashboard
                .depositAndWithdraw[this.props.currency.lowercaseCode()][
                operationType
            ]
        );
    }

    renderContent() {
        switch (this.state.activeTab) {
            case FiatDepositsAndWithdrawsController.DEPOSIT_OPERATION_NAME:
                /* if (this.state.loadingDepositLimitsStatus) {
                    return <Spinner />;
                } else {
                    return (
                        <Deposits
                            currency={this.props.currency}
                            availables={
                                this.state.data.depositLimitsStatus
                                    .total_available_by_currency
                            }
                            loadingCVU={this.state.loadingCVU}
                            cvu={this.state.data.cvu}
                        />
                    );
                } */
				return (
					<Deposits
						currency={this.props.currency}
						/* availables={
							this.state.data.depositLimitsStatus
								.total_available_by_currency
						} */
						loadingCVU={this.state.loadingCVU}
						cvu={this.state.data.cvu}
					/>
				);
            case FiatDepositsAndWithdrawsController.WITHDRAW_OPERATION_NAME:
                if (
                    this.state.loadingWithdrawsConfiguration ||
                    this.state.loadingAccountBalance
                ) {
                    return <Spinner />;
                } else {
                    return (
                        <Withdraws
                            currency={this.props.currency.lowercaseCode()}
                            loadingBankAccounts={this.state.loadingBankAccounts}
                            operationInProgress={this.state.operationInProgress}
                            bankAccountBook={this.state.data.bankAccountBook}
                            withdrawForm={this.state.newWithdrawForm}
                            balance={this.state.data.balance}
                            bankAccountForm={this.state.newBankAccount}
                            handleWithdrawAccountChange={
                                this.handleWithdrawAccountChange
                            }
                            handleWithdrawAmountChange={
                                this.handleWithdrawAmountChange
                            }
                            handleDeleteBankAccount={
                                this.handleDeleteBankAccount
                            }
                            handleConfirmWithdraw={this.confirmWithdraw}
                            handleBankAccountIdentifierChange={
                                this.handleBankAccountIdentifierChange
                            }
                            handleCheckAccountIdentifier={
                                this.checkAccountIdentifier
                            }
                            cleanWithdrawForm={this.cleanWithdrawForm}
                            handleConfirmationTokenChange={
                                this.handleConfirmationTokenChange
                            }
                            announceAddAccountSecondStepStarted={
                                this.announceAddAccountSecondStepStarted
                            }
                            handleCreateBankAccount={this.createBankAccount}
                            confirmationTokenSent={
                                this.state.confirmationTokenSent
                            }
                        />
                    );
                }
        }
    }

    renderErrorAlert() {
        return <OperationErrorAlert errorResponse={this.state.errorResponse} />;
    }

    render() {
        return (
            <>
                <h1>
                    {this.isADepositOperation() ? "Ingresar" : "Retirar"}{" "}
                    {this.props.currency.asVerbose(true, true)}
                </h1>
                <CardContent>
                    {/* <DepositWithdrawAlertModal loading={!this.state.data.balance} /> */}
                    {this.renderWarningModal()}
                    {this.renderContent()}
                    {/* <div className="d-none d-lg-block">
                        <Balances
                            balance={this.state.data.balance}
                            currenciesToShow={[
                                this.props.currency.lowercaseCode(),
                            ]}
                        />
                    </div> */}
                    {this.renderErrorAlert()}
                </CardContent>
            </>
        );
    }
    

    renderWarningModal() {
        const { accountStatus } = this.context || {};
        const showReducedWarning = accountStatus && accountStatus?.length > 0 && this.props.currency.lowercaseCode() === Currency.ARS;

        return (
            <ModalWithReadCheck
                modalName={"DepositsWarningModal"}
                type={Modal.MODAL_TYPES.warning}
                title="¡Atención!"
                canBeDismissed={false}
            >
                <p>Te recordamos que:</p>
                <ul>
                    {!showReducedWarning && (
                        <>
                            <li>
                                Sólo recibimos transferencias desde{" "}
                                <b className="highlighted">una cuenta propia</b> con{" "}
                                <b className="highlighted">tu mismo CUIT/CUIL</b>.
                    </li>
                    <li>
                        No recibimos transferencias que mandes desde cuentas
                        de <b className="highlighted">familiares o allegados</b>.
                            </li>
                        </>
                    )}
                    <li>
                        En general los depósitos son inmediatos, pero pueden
                        demorar hasta <b className="highlighted">3 horas hábiles</b>.
                    </li>
                </ul>
            </ModalWithReadCheck>
        );
    }
}
