import {ServerErrorResponse} from "../responses/generalResponses/ServerErrorResponse";
import {UnexpectedErrorResponse} from "../responses/generalResponses/UnexpectedErrorResponse";
import {AuthenticationErrorResponse} from "../responses/generalResponses/AuthenticationErrorResponse";
import {SignUpEndpoint} from "../endpoints/userAccount/registration/SignUpEndpoint";
import {ForgotPasswordEndpoint} from "../endpoints/userAccount/password/ForgotPasswordEndpoint";
import {RecoverPasswordEndpoint} from "../endpoints/userAccount/password/RecoverPasswordEndpoint";
import {ConfirmAccountEndpoint} from "../endpoints/userAccount/registration/ConfirmAccountEndpoint";
import {LoginEndpoint} from "../endpoints/userAccount/LoginEndpoint";
import {StaffLoginEndpoint} from "../endpoints/backoffice/StaffLoginEndpoint";
import {GetAccountBalanceEndpoint} from "../endpoints/buendolarAccount/GetAccountBalanceEndpoint";
import {GetPendingDocumentsEndpoint} from "../endpoints/buendolarAccount/GetPendingDocumentsEndpoint";
import {SignPendingDocumentsEndpoint} from "../endpoints/buendolarAccount/SignPendingDocumentsEndpoint";
import {GetWithdrawConfigurationEndpoint} from "../endpoints/coin/GetWithdrawConfigurationEndpoint";
import {MonthlyAnnualBalancesAndLimitsEndpoint} from "../endpoints/buendolarAccount/MonthlyAnnualBalancesAndLimitsEndpoint";
import {BuyEndpoint} from "../endpoints/purchaseAndSale/BuyEndpoint";
import {SellEndpoint} from "../endpoints/purchaseAndSale/SellEndpoint";
import {GetMarketTickerEndpoint} from "../endpoints/market/GetMarketTickerEndpoint";
import {GetMarketsEndpoint} from "../endpoints/market/GetMarketsEndpoint";
import {GetUserBankAccountsEndpoint} from "../endpoints/buendolarAccount/GetUserBankAccountsEndpoint";
import {MakeFiatWithdrawEndpoint} from "../endpoints/withdraws/MakeFiatWithdrawEndpoint.js";
import {CheckBankAccountIdentifierEndpoint} from "../endpoints/buendolarAccount/CheckBankAccountIdentifierEndpoint";
import {AddBankAccountEndpoint} from "../endpoints/buendolarAccount/AddBankAccountEndpoint";
import {DeleteBankAccountEndpoint} from "../endpoints/buendolarAccount/DeleteBankAccountEndpoint";
import {ReadDNIImagesEndpoint} from "../endpoints/verification/ReadDNIImagesEndpoint";
import {UploadDNIFrontEndpoint} from "../endpoints/verification/UploadDNIFrontEndpoint";
import {UploadDNIBackEndpoint} from "../endpoints/verification/UploadDNIBackEndpoint";
import {SendIdentificationDataEndpoint} from "../endpoints/verification/SendIdentificationDataEndpoint";
import {SendSelfieEndpoint} from "../endpoints/verification/SendSelfieEndpoint";
import {ConfirmPersonalDataEndpoint} from "../endpoints/verification/ConfirmPersonalDataEndpoint";
import {UpdatePersonalDataEndpoint} from "../endpoints/verification/UpdatePersonalDataEndpoint";
import {VerifyUserEndpoint} from "../endpoints/verification/VerifyUserEndpoint";
import {GetUserActivityEndpoint} from "../endpoints/userProfile/GetUserActivityEndpoint";
import {GetUserProfileEndpoint} from "../endpoints/userProfile/GetUserProfileEndpoint";
import {GetUserInformationEndpoint} from "../endpoints/userProfile/GetUserInformationEndpoint";
import {EditUserProfileEndpoint} from "../endpoints/userProfile/EditUserProfileEndpoint";
import {EditUserCommercialDataEndpoint} from "../endpoints/userProfile/EditUserCommercialDataEndpoint";
import {EditUserPepDeclarationProfileEndpoint} from "../endpoints/userProfile/EditUserPepDeclarationProfileEndpoint"
import {ChangePasswordEndpoint} from "../endpoints/userAccount/password/ChangePasswordEndpoint";
import {SetExtraPersonalDataEndpoint} from "../endpoints/verification/SetExtraPersonalDataEndpoint";
import {GetDepositAddressesEndpoint} from "../endpoints/coin/GetDepositAddressesEndpoint";
import {MakeCoinWithdrawEndpoint} from "../endpoints/coin/MakeCoinWithdrawEndpoint";
import {GetUserInvestmentStatusEndpoint} from "../endpoints/investments/GetUserInvestmentStatusEndpoint";
import {SignInvestingDocumentEndpoint} from "../endpoints/documents/SignInvestingDocumentEndpoint";
import {StartInvestingEndpoint} from "../endpoints/investments/StartInvestingEndpoint";
import {StopInvestingEndpoint} from "../endpoints/investments/StopInvestingEndpoint";
import {SendConfirmationEmailEndpoint} from "../endpoints/userAccount/registration/SendConfirmationEmailEndpoint";
import {GetOrCreateCVUEndpoint} from "../endpoints/buendolarAccount/GetOrCreateCVUEndpoint";
//import {GetDepositLimitsStatusEndpoint} from "../endpoints/buendolarAccount/GetDepositLimitsStatusEndpoint";
import {GetUserApiKeysEndpoint} from "../endpoints/userAccount/apiKeys/GetUserApiKeysEndpoint";
import {DeleteUserApiKeyEndpoint} from "../endpoints/userAccount/apiKeys/DeleteUserApiKeyEndpoint";
import {CreateUserApiKeyEndpoint} from "../endpoints/userAccount/apiKeys/CreateUserApiKeyEndpoint";
import {GetConfigurationSectionsEndpoint} from "../endpoints/userAccount/GetConfigurationSectionsEndpoint";
import {ListUserWalletsEndpoint} from "../endpoints/coin/ListUserWalletsEndpoint";
import {ChainalysisWalletCheckEndpoint} from "../endpoints/buendolarAccount/ChainalysisWalletCheckEndpoint";
import {CreateWalletBookEntryEndpoint} from "../endpoints/buendolarAccount/CreateWalletBookEntryEndpoint";
import {DeleteWalletBookEntryEndpoint} from "../endpoints/buendolarAccount/DeleteWalletBookEntryEndpoint";
import { AuthorizeApplicationEndpoint } from '../endpoints/oauth/AuthorizeApplication';
import { GetApplicationEndpoint } from '../endpoints/oauth/GetApplication';
import { ListApplicationsEndpoint } from '../endpoints/oauth/ListApplications';
import { RevokeTokensEndpoint } from '../endpoints/oauth/RevokeTokens';
import { TotpSetupEndpoint } from '../endpoints/userAccount/TotpSetupEndpoint';
import { TotpDeactivateEndpoint } from '../endpoints/userAccount/TotpDeactivateEndpoint';
import { GetCurrenciesEndpoint } from '../endpoints/market/GetCurrenciesEndpoint';
import { LoginWebviewEndpoint } from "../endpoints/userAccount/LoginWebviewEndpoint";
import { TOTPLoginEndpoint } from "../endpoints/userAccount/TOTPLoginEndpoint";
// import { GetOperationLimitStatusEndpoint } from "../endpoints/buendolarAccount/GetOperationLimitStatusEndpoint";
import { ResendAuthorizationLoginEndpoint } from "../endpoints/authorization/devices/ResendAuthorizationLoginEndpoint";
import { GetTaxesEndpoint } from "../endpoints/withdraws/GetTaxesEndpoint";
import { LogoutSessionEndpoint } from "../endpoints/userAccount/LogoutSessionEndpoint";
import { StopInvestingAllEndpoint } from "../endpoints/investments/StopInvestingAllEndpoint";
import { SignInvestingAllEndpoint } from "../endpoints/documents/SignInvestingAll";
import { GetAccountStatusEndpoint } from "../endpoints/deposits/GetAccountStatusEndpoint";

class ApiClient {
    constructor(
        requester,
        onServerErrorDo = () => {
        },
        onAuthorizationErrorDo = () => {
        },
        onExceptionDo = () => {
        },
    ) {
        this._requester = requester;
        this._onServerErrorDo = onServerErrorDo;
        this._onExceptionDo = onExceptionDo;
        this._onAuthorizationErrorDo = onAuthorizationErrorDo;
        this._handleResponse = this._handleResponse.bind(this);
        this._handleException = this._handleException.bind(this);
    }

    _handleException(exception) {
        return this._onExceptionDo(exception)
    }

    _handleResponse(response, onResponse) {
        if (response instanceof ServerErrorResponse || response instanceof UnexpectedErrorResponse) {
            return this._onServerErrorDo(response);
        }

        if (response instanceof AuthenticationErrorResponse) {
            return this._onAuthorizationErrorDo(response);
        }

        return onResponse(response);
    }

    forgotPassword(email, recaptcha, onResponse) {
        let requestData = {
            email: email || '',
            recaptcha: recaptcha || '',
        };

        return this._requester.call({
            endpoint: new ForgotPasswordEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    recoverPassword(email, password, token, onResponse) {
        let requestData = {
            email: email || '',
            password: password || '',
            token: token || '',
        };

        this._requester.call({
            endpoint: new RecoverPasswordEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    signUp(recaptcha, email, password, onResponse) {
        let requestData = {
            email: email || '',
            password: password || '',
            recaptcha
        };

        this._requester.call({
            endpoint: new SignUpEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    confirmAccount(user, token, onResponse) {
        let requestData = {
            username_b64: user,
            confirmation_token: token,
        };

        this._requester.call({
            endpoint: new ConfirmAccountEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    login(email, password, deviceUuid, confirmationToken, onResponse, recaptcha = null, client_id) {
        let requestData = {
            email: email,
            password: password,
            uuid: deviceUuid,
            confirmation_token: confirmationToken,
            recaptcha: recaptcha,
        };

        if (client_id) requestData.client_id = client_id;

        this._requester.call({
            endpoint: new LoginEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    TOTPLogin(email, password, token, deviceUuid, onResponse) {
        let requestData = {
            email: email,
            password: password,
            second_authentication_token: token,
            uuid: deviceUuid
        };

        this._requester.call({
            endpoint: new TOTPLoginEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    sendConfirmationEmail(email, onResponse) {
        let requestData = {
            email: email
        };

        this._requester.call({
            endpoint: new SendConfirmationEmailEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    staffLogin(email, password, onResponse) {
        let requestData = {
            email: email,
            password: password,
        };

        this._requester.call({
            endpoint: new StaffLoginEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    resendAuthorizationLogin(identification, onResponse) {
        let requestData = { identification };
        this._requester.call({
            endpoint: new ResendAuthorizationLoginEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    /* getOperationLimitStatus(onResponse) {
        this._requester.call({
            endpoint: new GetOperationLimitStatusEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
        });
    } */

    getAccountBalance(onResponse) {
        this._requester.call({
            endpoint: new GetAccountBalanceEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
        });
    }

    signPendingDocuments(documentType, onResponse) {
        let requestData = {
            document_types: documentType
        };

        this._requester.call({
            endpoint: new SignPendingDocumentsEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }
    getPendingDocuments(onResponse) {
        this._requester.call({
            endpoint: new GetPendingDocumentsEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
        });
    }

    getWithdrawsConfiguration(currency, onResponse) {
        this._requester.call({
            endpoint: new GetWithdrawConfigurationEndpoint(currency),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
        });
    }

    /* getDepositLimitsStatus(onResponse) {
        this._requester.call({
            endpoint: new GetDepositLimitsStatusEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
        });
    } */

    getMonthlyAnnualBalancesAndLimits(onResponse) {
        this._requester.call({
            endpoint: new MonthlyAnnualBalancesAndLimitsEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
        });
    }

    buy(marketIdentifier, amount, onResponse) {
        const requestData = {
            market_identifier: marketIdentifier,
            volume: amount.toString()
        };

        this._requester.call({
            endpoint: new BuyEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    sell(marketIdentifier, amount, onResponse) {
        const requestData = {
            market_identifier: marketIdentifier,
            volume: amount.toString()
        };

        this._requester.call({
            endpoint: new SellEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    getCurrencies(onResponse) {
        // when we return it as a promise we need to return the requester call
        return this._requester.call({
            endpoint: new GetCurrenciesEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            returnAsPromise: true,
        });
    }

    getTaxes(onResponse) {
        return this._requester.call({
            endpoint: new GetTaxesEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            returnAsPromise: true,
        });
    }

    getMarkets(onResponse) {
        // when we return it as a promise we need to return the requester call
        return this._requester.call({
            endpoint: new GetMarketsEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            returnAsPromise: true,
        });
    }

    getMarketTicker(onResponse) {
        this._requester.call({
            endpoint: new GetMarketTickerEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
        });
    }

    getBankAccountsBook(onResponse) {
        this._requester.call({
            endpoint: new GetUserBankAccountsEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
        });
    }

    getWalletBook(onResponse) {
        this._requester.call({
            endpoint: new ListUserWalletsEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
        });
    }

    fiatWithdraw(currency, amount, accountId, taxes, onResponse) {
        const requestData = {
            amount: amount,
            id: accountId,
            taxes: taxes
        };

        this._requester.call({
            endpoint: new MakeFiatWithdrawEndpoint(currency),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    logoutUserSession(onResponse) {
        const requestData = {};

        this._requester.call({
            endpoint: new LogoutSessionEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    checkBankAccountIdentifier(accountIdentifier, currency, onResponse) {
        const requestData = {
            uniform_key_or_alias: accountIdentifier,
            currency: currency
        };

        this._requester.call({
            endpoint: new CheckBankAccountIdentifierEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    createBankAccount(bankAccount, confirmationToken, onResponse) {
        const requestData = {
            uniform_key_or_alias: bankAccount.uniformKey() || bankAccount.alias(),
            currency: bankAccount.currency(),
            confirmation_token: confirmationToken,
        };

        this._requester.call({
            endpoint: new AddBankAccountEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    chainalysisWalletCheck(wallet, onResponse) {
        const requestData = {
            address: wallet.address,
            currency: wallet.currency,
        };
        this._requester.call({
            endpoint: new ChainalysisWalletCheckEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    createWallet(wallet, confirmationToken, onResponse) {
        const requestData = {
            address: wallet._address,
            currency: wallet.currency(),
            alias: wallet.alias(),
            confirmation_token: confirmationToken,
        };

        this._requester.call({
            endpoint: new CreateWalletBookEntryEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    deleteBankAccount(bankAccount, onResponse) {
        const requestData = {
            id: bankAccount.accountIdentifier(),
            currency: bankAccount.currency()
        };

        this._requester.call({
            endpoint: new DeleteBankAccountEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    deleteWalletBookEntry(walletBookEntry, onResponse) {
        const requestData = {
            id: walletBookEntry.walletId(),
            currency: walletBookEntry.currency()
        };

        this._requester.call({
            endpoint: new DeleteWalletBookEntryEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    sendIdentificationData(dniNumber, gender, dniOrder, onResponse) {
        const requestData = {
            dni_number: dniNumber,
            gender: gender,
            dni_order: dniOrder
        };

        this._requester.call({
            endpoint: new SendIdentificationDataEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    sendSelfie(selfie, onResponse) {
        const requestData = {
            image_file: selfie
        };

        this._requester.call({
            endpoint: new SendSelfieEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    uploadDniFront(dniFrontImage, onResponse) {
        const requestData = {
            image_file: dniFrontImage
        };

        this._requester.call({
            endpoint: new UploadDNIFrontEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    uploadDniBack(dniBackImage, onResponse) {
        const requestData = {
            image_file: dniBackImage
        };

        this._requester.call({
            endpoint: new UploadDNIBackEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    readDNIImages(onResponse) {
        this._requester.call({
            endpoint: new ReadDNIImagesEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception)
        });
    }

    confirmUserPersonalData(onResponse) {
        this._requester.call({
            endpoint: new ConfirmPersonalDataEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
        });
    }

    updateUserPersonalData(onResponse) {
        this._requester.call({
            endpoint: new UpdatePersonalDataEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
        });
    }

    verifyUser(onResponse) {
        this._requester.call({
            endpoint: new VerifyUserEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
        });
    }

    userActivity(filters, onResponse) {
        this._requester.call({
            endpoint: new GetUserActivityEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: {
                ...filters,
                "swap_mode": false
            }
        });
    }

    getUserProfile(onResponse) {
        this._requester.call({
            endpoint: new GetUserProfileEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
        });
    }

    getUserInformation(onResponse) {
        this._requester.call({
            endpoint: new GetUserInformationEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
        });
    }

    editUserProfile(fieldName, fieldValue, onResponse) {
        const fieldNameMap = { phoneNumber: 'phone', maritalStatus: 'marital_status' };
        const requestData = {
            field_name: fieldNameMap[fieldName],
            field_value: fieldValue,
        };

        this._requester.call({
            endpoint: new EditUserProfileEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    editUserCommercialDataProfile(commercialActivity, industry, userDefinedIndustry, onResponse) {
        const requestData = {
            commercial_activity: commercialActivity,
            industry: industry,
            user_defined_industry: userDefinedIndustry,
        };

        this._requester.call({
            endpoint: new EditUserCommercialDataEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    editUserPepDeclarationProfile(is_pep, onResponse) {
        const requestData = { is_pep: is_pep };
        this._requester.call({
            endpoint: new EditUserPepDeclarationProfileEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData,
        });
    }

    changePassword(oldPassword, newPassword, newPasswordConfirmation, onResponse) {
        const requestData = {
            old_password: oldPassword,
            new_password: newPassword,
            new_password_confirmation: newPasswordConfirmation
        };

        this._requester.call({
            endpoint: new ChangePasswordEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    setUserExtraPersonalData(activity, industry, user_defined_industry, maritalStatus, isPEP, isObligatedSubject, countryOfResidence,
        onResponse) {
        const requestData = {
            activity: activity,
            ud_industry: user_defined_industry,
            industry: industry,
            marital_status: maritalStatus,
            is_pep: isPEP,
            declares_self_as_obligated_subject: isObligatedSubject,
            country_of_residence: countryOfResidence,
        };

        this._requester.call({
            endpoint: new SetExtraPersonalDataEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    updateUserExtraPersonalData(activity, industry, userDefinedIndustryField, maritalStatus, isPEP, isObligatedSubject,
        onResponse) {
        const requestData = {
            activity: activity,
            industry: industry,
            ud_industry: userDefinedIndustryField,
            marital_status: maritalStatus,
            declares_self_as_pep: isPEP,
            declares_self_as_obligated_subject: isObligatedSubject,
        };

        this._requester.call({
            endpoint: new UpdatePersonalDataEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    getDepositAddresses(onResponse) {
        this._requester.call({
            endpoint: new GetDepositAddressesEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
        });
    }

    getOrCreateCVU(onResponse) {
        this._requester.call({
            endpoint: new GetOrCreateCVUEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
        });
    }

    coinWithdraw(currency, amount, walletId, onResponse) {
        const requestData = {
            amount: amount,
            wallet_id: walletId
        };

        this._requester.call({
            endpoint: new MakeCoinWithdrawEndpoint(currency),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    getInvestmentStatus(onResponse) {
        this._requester.call({
            endpoint: new GetUserInvestmentStatusEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
        });
    }

    startInvesting(onResponse) {
        this._requester.call({
            endpoint: new StartInvestingEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
        });
    }

    signInvestingDocument(onResponse) {
        this._requester.call({
            endpoint: new SignInvestingDocumentEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
        });
    }

    newSignInvestingDocument(onResponse) {
        const requestData = {
            document_types: ["IN"]
        };

        this._requester.call({
            endpoint: new SignInvestingAllEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    stopInvesting(onResponse) {
        this._requester.call({
            endpoint: new StopInvestingEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
        });
    }

    newStopInvesting(onResponse) {
        this._requester.call({
            endpoint: new StopInvestingAllEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
        });
    }

    getConfigurationSections(onResponse) {
        this._requester.call({
            endpoint: new GetConfigurationSectionsEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
        });
    }

    userApiKeys(onResponse) {
        this._requester.call({
            endpoint: new GetUserApiKeysEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
        });
    }

    createApiKey(apiKeyName, onResponse) {
        const requestData = {
            name: apiKeyName
        };

        this._requester.call({
            endpoint: new CreateUserApiKeyEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    deleteApiKey(apiKey, onResponse) {
        const requestData = {
            key: apiKey.key
        };

        this._requester.call({
            endpoint: new DeleteUserApiKeyEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData
        });
    }

    authorizeApplication(clientId, redirectUri, scope, allow, state, confirmationToken, onResponse, onException = this._handleException) {
        const requestData = {
            response_type: 'code',
            client_id: clientId,
            redirect_uri: redirectUri,
            allow: allow,
            scope: scope,
            state: state,
            confirmation_token: confirmationToken,
        };

        this._requester.call({
            endpoint: new AuthorizeApplicationEndpoint(),
            onResponse: response => this._handleResponse(response, onResponse),
            onException: onException,
            data: requestData,
        });
    }

    getOauthApplication(clientId, onResponse) {
        this._requester.call({
            endpoint: new GetApplicationEndpoint(clientId),
            onResponse: response => this._handleResponse(response, onResponse),
            onException: exception => this._handleException(exception),
        });
    }

    getOauthApplications(onResponse) {
        this._requester.call({
            endpoint: new ListApplicationsEndpoint(),
            onResponse: response => this._handleResponse(response, onResponse),
            onException: exception => this._handleException(exception),
        });
    }

    revokeOauthTokens(clientId, onResponse) {
        const requestData = {
            id: clientId,
        };
        this._requester.call({
            endpoint: new RevokeTokensEndpoint(),
            onResponse: response => this._handleResponse(response, onResponse),
            onException: exception => this._handleException(exception),
            data: requestData,
        });
    }

    setupTotp(secret, token, onResponse) {
        const requestData = { secret, token };

        this._requester.call({
            endpoint: new TotpSetupEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData,
        });
    }

    deactivateTotp(onResponse) {
        const requestData = {};

        this._requester.call({
            endpoint: new TotpDeactivateEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            onException: (exception) => this._handleException(exception),
            data: requestData,
        });
    }

    getAccountStatus(onResponse) {
        return this._requester.call({
            endpoint: new GetAccountStatusEndpoint(),
            onResponse: (response) => this._handleResponse(response, onResponse),
            returnAsPromise: true,
        });
    }

}

export default ApiClient;
