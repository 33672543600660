import {SuccessfulApiResponse} from "../generalResponses/SuccessfulApiResponse";

export class GetAccountStatusSuccessfulResponse extends SuccessfulApiResponse {
    static defaultResponse() {
        return [{
            "uniform_key": "",
            "type": "CVU",
            "currency": "ARS",
            "alias": "",
            "bank_name": "",
            "owner_name": "",
            "owner_tax_id": 0
        }];
    }

    static understandThis(jsonResponse) {
        return Array.isArray(jsonResponse);
    }
    
    accounts() {
        return this.content().accounts;
    }
} 